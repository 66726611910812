<template>
    <div style="text-align: left;">
        <el-card>
            <el-tabs v-model="activeName" @tab-click="handleClick" v-if="0">
                <el-tab-pane label="编辑新闻" name="1"></el-tab-pane>
                <el-tab-pane label="微信导入" name="2"></el-tab-pane>
            </el-tabs>
            <el-form ref="form" :model="form" :rules="formRules" label-width="20px" v-if="activeName=='1'">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="" prop="enterpriseNewsAlbumId">
                            <div><span class="red">*</span>新闻分类</div>
                            <el-select v-model="form.enterpriseNewsAlbumId" placeholder="请选择活动区域" style="width: 100%;" v-if="classifyList">
                                <el-option v-for="(v,i) of classifyList" :label="v.newsAlbumName" :key="i" :value="v.id"></el-option>
                                <!-- <el-option label="区域二" value="beijing"></el-option> -->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item label="" prop="itemTital">
                            <div><span class="red">*</span>新闻标题</div>
                            <el-col :span="11">
                                <el-input v-model="form.itemTital"></el-input>
                            </el-col>
                            <el-col :span="11" class="df-ai-c">
                                <el-color-picker v-model="form.itemTitleColor" class="m-l-10"></el-color-picker><span class="m-r-20 m-l-10">颜色</span>
                                <el-checkbox v-model="form.itemTitleIsBold">加粗</el-checkbox>
                                <el-checkbox v-model="form.itemTitleIsHref">网址跳转</el-checkbox>
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6" v-if="form.itemTitleIsHref">
                        <el-form-item label="" prop="itemTitleIsHref">
                            <div><span class="red">*</span>跳转网址</div>
                            <el-input v-model="form.itemHref"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="">
                            <!-- 转载类型 0原创 1投稿 2转载 -->
                            <div><span class="red">*</span>转载类型</div>
                            <el-select v-model="form.itemReprint" placeholder="请选择转载类型" style="width: 100%;">
                                <el-option label="原创" :value="0"></el-option>
                                <el-option label="投稿" :value="1"></el-option>
                                <el-option label="转载" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="" prop="itemReleasePeople">
                            <div><span class="red">*</span>发布人</div>
                            <el-input v-model="form.itemReleasePeople"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" v-if="form.itemReprint==2">
                        <el-form-item label="" prop="itemSource">
                            <div><span class="red">*</span>文章来源</div>
                            <el-input v-model="form.itemSource"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" v-if="0">
                        <el-form-item label="">
                            <div><span class="red">*</span>发布时间</div>
                            <el-date-picker v-model="value1" type="date" placeholder="选择日期" style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="" prop="itemPhone">
                            <div><span class="red">*</span>新闻封面</div>
                            <upload-image @uploadImageBack="uploadImageBack" class="avatar-uploader" size="mini">
                                <img v-if="form.itemPhone" :src="$oucy.ossUrl+form.itemPhone" class="avatar mini">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </upload-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item label="" prop="itemAbstract">
                            <div><span class="red">*</span>新闻摘要</div>
                            <el-input type="textarea" :rows="2" v-model="form.itemAbstract"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="">
                    <!-- 阅读权限 0不限制 1会员登录可阅 2全文付费可阅 3部分付费可阅 4指定身份类型可阅 -->
                    <div><span class="red">*</span>阅读权限</div>
                    <el-radio-group v-model="form.itemAuth">
                        <el-radio :label="0">不限制</el-radio>
                        <el-radio :label="1">会员登录可阅</el-radio>
                        <el-radio :label="2">全文付费可阅</el-radio>
                        <el-radio :label="3">部分付费可阅</el-radio>
                        <el-radio :label="4">指定会员(标识)组可阅</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" v-if="form.itemAuth==4" prop="identityAuthsArray">
                    <div><span class="red">*</span>选择指定标识</div>
                    <el-checkbox-group v-model="form.identityAuthsArray" v-if="ltIdentityList">
                        <el-checkbox v-for="(v,i) of ltIdentityList" :label="v.id" :value="v.id" :key="v.id">{{v.identityName}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="" v-if="form.itemAuth!=2" prop="itemContentFree">
                    <div><span class="red">*</span>新闻内容<span v-if="form.itemAuth==3">(非付费内容)</span></div>
                    <quill-editor class='editor' ref="myQuillEditor" v-model="form.itemContentFree" :options="quillEditorOption" />
                </el-form-item>
                <el-form-item label="" v-if="form.itemAuth==2 || form.itemAuth==3" prop="itemContentPay">
                    <div><span class="red">*</span>新闻内容<span v-if="form.itemAuth==3">(付费内容)</span></div>
                    <quill-editor class='editor' ref="myQuillEditor" v-model="form.itemContentPay" :options="quillEditorOption" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即发布</el-button>
                    <el-button>取消</el-button>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="form" label-width="20px" v-if="activeName=='2'">
                <el-form-item label="">
                    <div><span class="red">*</span>微信文章链接地址</div>
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit2">添加一篇</el-button>
                    <el-button @click="$oucy.back()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { enterpriseNewsAlbum, enterpriseNewsAlbumItem, ltIdentity } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

const toolbarOptions = [
    ["bold", "italic", ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
    [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    [{ font: [] }], // 字体种类-----[{ font: [] }]
    [{ align: [] }], // 对齐方式-----[{ align: [] }]
    ["clean"], // 清除文本格式-----['clean']
    ["image"] // 链接、图片、视频-----['link', 'image', 'video']
];

export default {
    name: "Photo",
    data() {
        return {
            // 下面是验证的
            formRules: {
                enterpriseNewsAlbumId: [
                    { required: true, message: '请选择新闻分类', trigger: 'blur' },
                ],
                itemTital: [
                    { required: true, message: '请输入新闻标题', trigger: 'blur' },
                ],
                itemTitleIsHref: [
                    { required: true, message: '请输入跳转网址', trigger: 'blur' },
                ],
                itemReleasePeople: [
                    { required: true, message: '请输入发布人', trigger: 'blur' },
                ],
                itemSource: [
                    { required: true, message: '请输入文章来源', trigger: 'blur' },
                ],
                itemPhone: [
                    { required: true, message: '请选择新闻封面', trigger: 'blur' },
                ],
                identityAuthsArray: [
                    { required: true, message: '请选择指定标识', trigger: 'blur' },
                ],
                itemAbstract: [
                    { required: true, message: '请输入新闻摘要', trigger: 'blur' },
                ],
                itemContentFree: [
                    { required: true, message: '请输入新闻内容', trigger: 'blur' },
                ],
                itemContentPay: [
                    { required: true, message: '请输入新闻内容', trigger: 'blur' },
                ],
            },
            activeName: '1',
            form2Rules:{},
            form2:{},
            form: {
                // 新闻分类
                enterpriseNewsAlbumId: null,
                // 新闻标题
                itemTital: '',
                // 颜色
                itemTitleColor: '',
                // 加粗
                itemTitleIsBold: false,
                // 网址跳转
                itemTitleIsHref: false,
                // 跳转网址
                itemHref: '',
                // 转载类型
                itemReprint: 0,
                // 发布人
                itemReleasePeople: '',
                // 文章来源
                itemSource: '',
                // 新闻封面
                itemPhone: '',
                // 新闻摘要
                itemAbstract: '',
                // 阅读权限
                itemAuth: 0,
                itemSortWeight: 0,
                identityAuths:null,
                identityAuthsArray: [],
            },
            quillEditorOption: {
                placeholder: '填写产品简介',
                theme: "snow",
                modules: {
                    toolbar: toolbarOptions,
                    imageDrop: true,
                    imageResize: {
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white"
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"]
                    },
                },
            },
            classifyList: [],
            ltIdentityList: [],
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.form.enterpriseNewsAlbumId = this.$route.query.enterpriseNewsAlbumId
        this.getAllEnterpriseNewsAlbumsClient()
        this.findFirstIdentity()
        if(this.$route.query.id){
            this.id=this.$route.query.id
            this.getEnterpriseNewsAlbumItems()
        }
    },
    methods: {
        // 查询用户的新闻册
        getAllEnterpriseNewsAlbumsClient() {
            enterpriseNewsAlbum.getAllEnterpriseNewsAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                if (res) {
                    this.classifyList = res
                    if (!this.form.enterpriseNewsAlbumId && this.classifyList && this.classifyList.length) {
                        this.form.enterpriseNewsAlbumId = this.classifyList[0].id
                    }
                }
            })
        },
        // 查询新闻
        getEnterpriseNewsAlbumItems() {
            enterpriseNewsAlbumItem.getEnterpriseNewsAlbumItems({ id: this.id }).then(res => {
                if (res) {
                   this.form=Object.assign(this.form,res)
                }
            })
        },
        handleClick() {},
        submitClassifyForm() {},
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.findFirstIdentityFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.findFirstIdentityFurnitureSpu();
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 上传回调
        uploadImageBack(v) {
            this.form.itemPhone = v
        },
        // 点击发布触发
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if(this.form.itemAuth==4){
                        this.form.identityAuths= JSON.stringify(this.form.identityAuthsArray)
                    }
                    if(this.form.id){
                        this.updateEnterpriseNewsAlbumItem()
                    }else{
                        this.addenterpriseNewsAlbumItem()
                    }
                }
            })
        },
        onSubmit2(){

            enterpriseNewsAlbumItem.importFromWechat().then(res=>{})
        },
        // 新添新闻
        addenterpriseNewsAlbumItem() {
            enterpriseNewsAlbumItem.addEnterpriseNewsAlbumItem(this.form).then(res => {
                this.$oucy.back()
            })
        },
        // 编辑新闻
        updateEnterpriseNewsAlbumItem() {
            enterpriseNewsAlbumItem.updateEnterpriseNewsAlbumItem(this.form).then(res => {
                this.$oucy.back()
            })
        },
        // 查询指定会员
        findFirstIdentity() {
            ltIdentity.findFirstIdentity({ identityIsEnterprise: null }).then(res => {
                this.ltIdentityList = res
            })
        },
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}

.red {
    color: #ff0000;
}

.avatar-uploader-icon {
    border: 1px dashed #c0ccda;
    width: 54px;
    height: 54px;
    line-height: 54px;
}

.avatar.mini {
    width: 54px;
    height: 54px;
}
</style>